import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Process from "../components/process"
import InfoWindow from "../components/infoWindow"
import Testimonials from "../components/testimonials"

//import InfoSection from ""

const more = [
  <div>
    <ul>
      <li> Inspection Report </li>
      <li> Trash Chute Clean </li>
      <li> Hopper Door Clean </li>
      <li> Compactor Clean </li>
      <li> Collection Room Clean </li>
      <li> Bin Clean </li>
      <li> Service Report </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
  <div>
    <ul>
      <li>A short professional analysis</li>
      <li>All relevant photographs</li>
      <li>Date of needed services</li>
      <li>Cost of needed services</li>
    </ul>
    <p>Contact us to recieve a demo inspection or service report.</p>
  </div>,

  <div>
    <ul>
      <li> Deoderiser package </li>
      <li> 2 FREE Unblocking Call-outs </li>
      <li> Personalised Service Report </li>
      <li> FREE Periodic Chute Updates </li>
      <li> 24/7 Chute Unblocking Call-outs </li>
      <li> Negotiable price </li>
      <li> 100% satisfaction guarantee </li>
      <li> and more... </li>
    </ul>
    Contact us to customise the service or recieve a demo service report.
    <p></p>
  </div>,
]
const InspectionReporting = (
  <div>
    <h2> Regular Chute Servies</h2>
    <p>
      <h4> Regular system cleanse: </h4> A full trash chute system cleanse
      includes:
      <ul>
        <li>Site inspections</li>
        <li>Photography for your FREE service report</li>
        <li>Garbage chute cleaning</li>
        <li>Hopper door cleaning</li>
        <li>Compactor cleaning</li>
        <li>Collection room cleaning</li>
        <li>Bin cleaning</li>
        <li>FREE Service Report</li>
      </ul>
      <h4> No obligation</h4> Custom or once-off free inspections with no
      obligations are welcome.
      <h4> Blocked chute? </h4> Our 24/7 on-call staff will remove the toughest
      blockages any time you need.
    </p>
  </div>
)

const RegularService = (
  <div>
    <h2> FREE Inspection Reporting</h2>
    <i></i>
    <p>
      <h4>Personal reports</h4>
      Chute professionals will inspect your trash chute system, with cleaning
      services quoted as needed. If no services are needed a free follow-up
      inspection is scheduled. Reports are sent conveniently to your email for
      your records.
      <br />
    </p>

    <p>
      <h4> Regular inspections </h4> Opt into our FREE regular reporting
      programme to stay ahead of residential complaints and costly damages. Our
      team periodically inspects your site and quotes services as they are
      needed.
      <h4> Once-off inspection: </h4> Unsure of your trash chute needs? Book a
      once-off free inspection or call our chute professionals for a chat.
    </p>
  </div>
)

const ExtraMile = (
  <div>
    <h2>We go the extra mile</h2>
    <p>
      <h4>Innovative </h4>
      Our chute experts are always looking to improve our service quality. Ask
      about the innovative deoderizing packages we provide to help improve your
      residents' relationship with the chute.
      <h4> Competitive pricing: </h4> Our competitors are often over-priced.
      Call us to negotiate.
      <h4> Streamlined: </h4> Opt into our FREE inspection programme and never
      worry about your chute again. We do the hard work so you don't have to.
    </p>
  </div>
)

const ChuteCleaning = () => {
  const data = useStaticQuery(graphql`
    query {
      image1: file(relativePath: { eq: "chute-cleaning-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image2: file(relativePath: { eq: "chute-cleaning-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image3: file(relativePath: { eq: "chute-cleaning-3.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      image4: file(relativePath: { eq: "chute-cleaning-4.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 624) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout style={{ height: "80vh" }}>
      <SEO
        description="Our south east Queensland expert team will keep your chute, waste equipment, and garbage room smelling fresh. Contact us for FREE inspections"
        lang="en"
        meta=""
        title="Professional Chute Cleaning | ChuteOff | Brisbane, Gold Coast, Sunshine Coast "
      />
      <section id="top">
        <InfoWindow
          id="chute-cleaning"
          title={<>Professional Chute Cleaning Services</>}
          subtitle="Professional chute cleaning and unblocking services across Brisbane, Gold Coast, and Sunshine Coast."
          panes={[InspectionReporting, RegularService, ExtraMile]}
          buttons={["BOOK SERVICE", "BOOK FREE INSPECTION", "CUSTOM JOB"]}
          button_subtitles={[
            "After every service you receive a FREE personalised service report.",
            "Not sure what service you need? Talk to a chute professional",
            "Request a custom service tailored to your needs",
          ]}
          images={[
            data.image1.childImageSharp.fluid,
            data.image2.childImageSharp.fluid,
            data.image3.childImageSharp.fluid,
          ]}
          more={more}
          expansion_title={[
            "What is involved in a standard chute system clean?",
            "What is included in the inspection report?",
            "What will I receive as a regular client?",
          ]}
        />
      </section>

      <section className="container">
        <div className="row pd-30">
          <div className="col-lg-6">
            <h2>A Fresh Chute Is Important</h2>
            <p>
              {" "}
              A dirty chute is an unhealthy chute, and if your trash chute
              smells, it needs professional attention. Every chute is different
              and our team of experts are waiting on-call to inspect and
              diagnose your chutes.
            </p>
            <p>A fresh chute is important because it: </p>
            <ul
              class="list-unstyled"
              style={{
                listStyleType: "disc",
                paddingLeft: "2em",
                paddingBottom: "1.8em",
              }}
            >
              <li>Keeps tenants happy and apartments full </li>
              <li>Minimises long-term maintenance costs </li>
              <li>Removes pest, bug and rodent problems </li>
              <li>Lengthens chute and chute door life </li>
              <li>Removes grease build-up and potential fire hazards </li>
              <li>Eliminates trash room odors </li>
              <li>Minimises risk of blockage </li>
              <li>Reduces chances of black mold </li>
            </ul>
          </div>
          <div className="col-lg-6">
            <img
              src={data.image4.childImageSharp.fluid}
              alt=""
              style={{ width: "100%" }}
            />
          </div>
        </div>
      </section>
      <Testimonials />
      <Process />
    </Layout>
  )
}

export default ChuteCleaning
