import React from "react"
import { AnchorLink } from "gatsby-plugin-anchor-links"

import Img from "gatsby-image"

import "../static/css/service-window.css"

const InfoWindow = ({
  id,
  title,
  subtitle,
  panes,
  buttons,
  button_subtitles,
  images,
  more,
  expansion_title,
}) => {
  return (
    <section id={id} class="service-info-window container mg-30">
      <div class="row pd-30">
        <div class="col-md-12 text-align-center">
          <h1>{title}</h1>
          <p>{subtitle}</p>
        </div>
      </div>
      <div class="row borders">
        <div class="col-lg-6">
          <Img fluid={images[0]} alt="" style={{ width: "100%" }} />
        </div>
        <div class="col-lg-6">
          {panes[0]}
          <div class="tab">
            <input type="checkbox" id="chck0" />
            <label class="tab-label" for="chck0" name="chck0">
              {expansion_title[0]}
            </label>
            <div class="tab-content">{more[0]}</div>
          </div>
          <div class="text-align-center">
            <AnchorLink className="primary-btn dark-btn" to="/#contact">
              {buttons[0]}
            </AnchorLink>
            <span className="i">{button_subtitles[0]}</span>
          </div>
        </div>
      </div>
      <div className="row borders">
        <div class="col-lg-6">
          <div>
            {panes[1]}

            <div class="tab">
              <input type="checkbox" id="chck1" />
              <label class="tab-label" for="chck1" name="chck1">
                {expansion_title[1]}
              </label>
              <div class="tab-content">{more[1]}</div>
            </div>

            <div class="text-align-center">
              <AnchorLink className="primary-btn dark-btn" to="/#contact">
                {buttons[1]}
              </AnchorLink>
              <span className="i">{button_subtitles[1]}</span>
            </div>
          </div>
        </div>
        <div class="col-lg-6">
          <Img fluid={images[1]} alt="" style={{ width: "100%" }} />
        </div>
      </div>
      <div className="row borders">
        <div class="col-lg-6">
          <Img fluid={images[2]} alt="" style={{ width: "100%" }} />
        </div>
        <div className="col-lg-6">
          <div>
            {panes[2]}
            <div class="tab">
              <input type="checkbox" id="chck2" />
              <label class="tab-label" for="chck2" name="chck2">
                {expansion_title[2]}
              </label>
              <div class="tab-content">{more[2]}</div>
            </div>
            <div class="text-align-center">
              <AnchorLink className="primary-btn dark-btn" to="/#contact">
                {buttons[2]}
              </AnchorLink>
              <span className="i">{button_subtitles[2]}</span>
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export default InfoWindow
